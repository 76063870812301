import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ImageUploader from './ImageUploader';

const UpdateLog = () => {
    const { log_id } = useParams(); // Hämta loggpostens ID från URL:en
    const [userId, setUserId] = useState('');
    const [title, setTitle] = useState('');
    const [year, setYear] = useState('');
    const [departureDateTime, setDepartureDateTime] = useState('');
    const [arrivalDateTime, setArrivalDateTime] = useState('');
    const [windDirection, setWindDirection] = useState('');
    const [windSpeed, setWindSpeed] = useState('');
    const [distance, setDistance] = useState('');
    const [position, setPosition] = useState('');
    const [description, setDescription] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [images, setImages] = useState([]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const { currentUser } = useAuth();
    const [showLoginMessage, setShowLoginMessage] = useState(false); // Statiskt tillstånd för att visa eller dölja meddelandet
    const [imagesToRemove, setImagesToRemove] = useState([]);

    useEffect(() => {
        if (currentUser === null) {
            navigate('/info');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        // Skapa en ny abort controller
        const abortController = new AbortController();
    
        // Hämta loggpostens data när komponenten laddas
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/showlog/${log_id}`, { signal: abortController.signal })
            .then(response => response.json())
            .then(data => {
                // Sätt state med data från servern
                setUserId(data.userId);
                setTitle(data.title);
                setYear(data.year);
                setDepartureDateTime(moment(data.departureDateTime).format('YYYY-MM-DDTHH:mm'));
                setArrivalDateTime(moment(data.arrivalDateTime).format('YYYY-MM-DDTHH:mm'));
                setWindDirection(data.windDirection);
                setWindSpeed(data.windSpeed);
                setDistance(data.distance);
                setPosition(data.position);
                setDescription(data.description);
                setImages(data.images);
            })
            .catch(error => {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Error:', error);
                }
            });
    
        // Avbryt fetch-anropet när komponenten avmonteras
        return () => {
            abortController.abort();
        };
    }, [log_id]); // Kör useEffect när `id` ändras

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/deletelog/${log_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'userId': userId // Skicka med userId i headern
            }
        })
            .then(response => response.text())
            .then(data => {
                console.log(data);
                navigate('/info'); // Navigera till startsidan efter en lyckad radering
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        handleClose();
    };

    const handleRemoveExistingImage = (event, indexToRemove) => {
        event.preventDefault();
        let imageToRemove = images[indexToRemove];
    
        // Ta bort '/images/' från imageToRemove
        imageToRemove = imageToRemove.replace('/images/', '');
    
        // Lägg till bilden i imagesToRemove
        setImagesToRemove(prevImages => [...prevImages, imageToRemove]);
    
        // Ta bort bilden från images-staten
        setImages(prevImages => prevImages.filter((image, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Kontrollera om användaren är densamma som har skapat loggen
        if (currentUser !== userId) {
            setShowLoginMessage(true);
            return;
        }

        // Återställ meddelandet
        setShowLoginMessage(false);
    // Upload the files
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

        const uploadResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/uploads`, {
            method: 'POST',
            body: formData
        });

        const uploadedImages = await uploadResponse.json();
        console.log('Uploaded images: ', uploadedImages);
        setUploadedImages(uploadedImages);

        // Lägg till de nya bilderna till images-arrayen
        const updatedImages = [...images, ...uploadedImages];

        // Skapa ett objekt med uppdaterade data att skicka till servern
        const logData = {
            userId,
            title,
            year,
            departureDateTime,
            arrivalDateTime,
            windDirection,
            windSpeed,
            distance,
            position,
            description,
            images: updatedImages // Använd updatedImages här
        };

        if (logData.distance === '') {
            logData.distance = null;
          }

    // Skicka en DELETE-begäran för varje bild i imagesToRemove
    imagesToRemove.forEach(imageToRemove => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/deleteimage/${imageToRemove}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'userId': userId // Skicka med userId i headern
            }
        })
            .then(response => response.text())
            .then(data => {
                console.log(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    });
console.log('log_id i updatelog: ', log_id);
console.log('user_id i updatelog: ', userId);
        //skicka till servern
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/updatelog/${log_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'userId': userId // Skicka med userId i headern
            },
            body: JSON.stringify(logData)
        })
            .then(response => response.text())
            .then(data => {
                console.log(data);
                navigate(`/showlog/${log_id}`); // Navigera till ShowLog efter en lyckad uppdatering
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div>

            <Card>
                <CardContent>
                    {showLoginMessage && (
                        <Alert severity="warning" onClose={() => setShowLoginMessage(false)}>
                            Du har inte behörighet att uppdatera denna logg.
                        </Alert>
                    )}
                    <Typography variant="h5">Uppdatera logg</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Rubrik"
                                    value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="År"
                                    value={year}
                                    onChange={(event) => setYear(event.target.value)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Avgångstid"
                                    type="datetime-local"
                                    value={departureDateTime}
                                    onChange={(event) => setDepartureDateTime(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Ankomsttid"
                                    type="datetime-local"
                                    value={arrivalDateTime}
                                    onChange={(event) => setArrivalDateTime(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Vindriktning"
                                    value={windDirection}
                                    onChange={(event) => setWindDirection(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Vindstyrka (m/s)"
                                    value={windSpeed}
                                    onChange={(event) => setWindSpeed(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Distans (nm)"
                                    value={distance}
                                    onChange={(event) => setDistance(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Från – Till"
                                    value={position}
                                    onChange={(event) => setPosition(event.target.value)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Beskrivning"
                                    value={description}
                                    multiline
                                    rows={4}
                                    onChange={(event) => setDescription(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <ImageUploader onUploadSuccess={setSelectedFiles} />
                                    <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                                        {images.map((imageUrl, index) => (
                                            <Box padding={2} key={index} width={300}>
                                                <Card sx={{ padding: 1, position: 'relative' }}>
                                                    <CardContent>
                                                        <button onClick={(event) => handleRemoveExistingImage(event, index)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '0',
                                                                right: '0',
                                                                background: 'red',
                                                                color: '#fff',
                                                                border: 'none',
                                                                cursor: 'pointer'
                                                            }}
                                                        >X</button>

                                                        <img src={imageUrl.replace('/images/', `${process.env.REACT_APP_SERVER_URL}/api/images/`)} alt={`Image ${index}`} style={{ width: '100%' }} />
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        ))}
                                        {uploadedImages.map((imageUrl, index) => (
                                            <Box padding={2} key={index} width={300}>
                                                <Card sx={{ padding: 1 }}>
                                                    <CardContent>
                                                        <img src={imageUrl} alt="Uppladdad bild" style={{ width: '100%' }} />
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        ))}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Box paddingTop={4} paddingRight={2} paddingBottom={4} paddingLeft={0}>
                            <Button variant="contained" color="primary" type="submit">Uppdatera</Button>
                            <Button variant="contained" color="secondary" style={{ marginLeft: '10px' }} onClick={() => navigate(`/showlog/${log_id}`)}>Tillbaka</Button>
                        </Box>
                    </form>
                </CardContent>
            </Card >
            <Box paddingTop={4} paddingRight={2} paddingBottom={4} paddingLeft={0}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="warning" onClick={handleClickOpen}>Radera logg</Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>Är du säker?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Är du säker på att du vill radera denna logg? Denna åtgärd kan inte ångras.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">Avbryt</Button>
                            <Button onClick={handleDelete} color="secondary">Radera</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>

        </div>
    );
}

export default UpdateLog;
