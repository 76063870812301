import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button, TextField } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import ImageUploader from './ImageUploader';
import Alert from '@mui/material/Alert';

const CreateServiceLog = () => {
  const { userId } = useAuth();
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [boatNameModel, setBoatNameModel] = useState('');
  const [engineType, setEngineType] = useState('');
  const [servicePersonOrCompany, setServicePersonOrCompany] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [engineService, setEngineService] = useState('');
  const [sailAndRig, setSailAndRig] = useState('');
  const [electronicsAndNavigation, setElectronicsAndNavigation] = useState('');
  const [hullAndDeck, setHullAndDeck] = useState('');
  const [rudderPropellerAndDrive, setRudderPropellerAndDrive] = useState('');
  const [fuelSystem, setFuelSystem] = useState('');
  const [batteryAndElectricalSystem, setBatteryAndElectricalSystem] = useState('');
  const [sparePartsAndReplacements, setSparePartsAndReplacements] = useState('');
  const [recommendedActions, setRecommendedActions] = useState('');
  const [costAndTime, setCostAndTime] = useState('');
  const [comments, setComments] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { currentUser } = useAuth();
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === null) {
      navigate('/info');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!currentUser) {
      setShowLoginMessage(true);
      return;
    }

    setShowLoginMessage(false);

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const uploadResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/uploads`, {
      method: 'POST',
      body: formData
    });

    const uploadedImages = await uploadResponse.json();
    console.log('Uploaded images: ', uploadedImages);

    const _id = (Math.floor(Math.random() * 1e12) + 1e12).toString().substring(1);
    console.log('_id:', _id);

    const serviceLogData = {
      _id,
      userId,
      title,
      date,
      boatNameModel,
      engineType,
      servicePersonOrCompany,
      serviceType,
      engineService,
      sailAndRig,
      electronicsAndNavigation,
      hullAndDeck,
      rudderPropellerAndDrive,
      fuelSystem,
      batteryAndElectricalSystem,
      sparePartsAndReplacements,
      recommendedActions,
      costAndTime,
      comments,
      images: uploadedImages
    };

    try {
      const createServiceLogResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/createservicelog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(serviceLogData)
      });

      if (!createServiceLogResponse.ok) {
        throw new Error(`HTTP error! status: ${createServiceLogResponse.status}`);
      }

      const responseData = await createServiceLogResponse.json();
      navigate(`/showservicelog/${responseData._id}`);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Card>
      <CardContent>
        {showLoginMessage && (
          <Alert severity="warning" onClose={() => setShowLoginMessage(false)}>
            Du måste vara inloggad för att skapa en ny servicelogg.
          </Alert>
        )}
        <Typography variant="h5">Ny servicelogg</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Titel"
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Datum"
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Båtens namn/modell"
                value={boatNameModel}
                onChange={e => setBoatNameModel(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Motor/typ"
                value={engineType}
                onChange={e => setEngineType(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Serviceperson eller företag"
                value={servicePersonOrCompany}
                onChange={e => setServicePersonOrCompany(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Typ av service/underhåll"
                value={serviceType}
                onChange={e => setServiceType(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Motorservice"
                value={engineService}
                onChange={e => setEngineService(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Segel och rigg"
                value={sailAndRig}
                onChange={e => setSailAndRig(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Elektronik och navigationssystem"
                value={electronicsAndNavigation}
                onChange={e => setElectronicsAndNavigation(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Skrov och däck"
                value={hullAndDeck}
                onChange={e => setHullAndDeck(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Roder, propeller, och drev"
                value={rudderPropellerAndDrive}
                onChange={e => setRudderPropellerAndDrive(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bränslesystem"
                value={fuelSystem}
                onChange={e => setFuelSystem(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Batteri och elsystem"
                value={batteryAndElectricalSystem}
                onChange={e => setBatteryAndElectricalSystem(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Reservdelar/byten"
                value={sparePartsAndReplacements}
                onChange={e => setSparePartsAndReplacements(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Rekommenderade åtgärder"
                value={recommendedActions}
                onChange={e => setRecommendedActions(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Kostnad och tid"
                value={costAndTime}
                onChange={e => setCostAndTime(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Kommentarer/Anmärkningar"
                value={comments}
                onChange={e => setComments(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ImageUploader onUploadSuccess={setSelectedFiles} />
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {uploadedImages.map((imageUrl, index) => (
                      <img key={index} src={imageUrl} alt="Uppladdad bild" style={{ width: '30%', height: 'auto', margin: '1%' }} />
                    ))}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Skapa servicelogg
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateServiceLog;