import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';

const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: 'arial, sans-serif',
    h1: {
      fontFamily: 'arial, sans-serif',
      fontSize: '28px',
      padding: '10px 10px 10px 0px',
      '@media (min-width:768px)': {
        fontSize: '32px',
        padding: '10px 10px 10px 0px',
      },
    },
    h2: {
      fontFamily: 'arial, sans-serif',
      fontSize: '24px',
      padding: '10px 10px 10px 0px',
      '@media (min-width:768px)': {
        fontSize: '28px',
        padding: '10px 10px 10px 0px',
      },
    },
    h3: {
      fontFamily: 'arial, sans-serif',
      fontSize: '20px',
      padding: '10px 10px 10px 0px',
      '@media (min-width:768px)': {
        fontSize: '24px',
        padding: '10px 10px 10px 0px',
      },
    },
    h4: {
      fontFamily: 'arial, sans-serif',
      fontSize: '16px',
      padding: '10px 10px 0px 0px',
      '@media (min-width:768px)': {
        fontSize: '20px',
        padding: '10px 10px 0px 0px',
      },
    },
    // And so on for the other variants
  },
});


function LogTheme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{
        '.ingress': {
          fontSize: '1.5rem',
          fontStyle: 'italic',
          marginBottom: '50px',
          width: '75%',
        },
        '.image-300': {
          width: '300px',
          padding: '20px',
        },
        '.menylink': {
          color: 'white',
          fontWeight: '500 !important',
          fontSize: '18px !important',
          lineHeight: '25px !important',
          marginRight: '10px',
          textDecoration: 'none',
          transition: 'color 0.3s', // Lägg till en övergång för smidig färgändring
        },
        '.menylink:hover': {
          cursor: 'pointer',
          color: '#999',
          transition: 'color 0.3s', // Lägg till en övergång för smidig färgändring
        },
        '.links': {
          display: 'none',
          flexDirection: 'column',
          position: 'absolute', /* Gör menyn positionerad absolut för att kunna placera den utanför headern */
          top: '100%', /* Placera menyn precis under headern */
          left: '0', /* Placera menyn längst till vänster */
          width: '100%', /* Bredden på menyn ska vara lika med headerns bredd */
          backgroundColor: '#333589', /* Bakgrundsfärg för menyn */
          padding: '30px', /* Lägg till lite utrymme runt länkarna */
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', /* Lägg till en skugga för att separera menyn från resten av sidan */
          zIndex: '99',
        },
        '.links.show': {
          display: 'flex',
        },
        '.hamburger': {
          display: 'block',
        },
        '.hamburger:hover': {
          cursor: 'pointer',
        },
        '@media (min-width: 768px)': {
          '.links': {
            display: 'flex',
            flexDirection: 'row',
            position: 'static', /* Återgå till normal positionering */
            top: 'auto',
            left: 'auto',
            width: 'auto',
            backgroundColor: 'transparent', /* Återgå till transparent bakgrund */
            padding: '0', /* Återgå till standard padding */
            boxShadow: 'none', /* Ta bort skuggan */
          },
          '.hamburger': {
            display: 'none !important',
          },
        },

      }} />
      {children}
    </ThemeProvider>
  );
}

export default LogTheme;