import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function YearLogs() {
  const [logs, setLogs] = useState([]);
  const { year } = useParams();
  const { userId } = useAuth();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/logs/${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-User-Id': userId,
      },
    })
      .then((response) => response.json())
      .then((data) => setLogs(data))
      .catch((error) => console.error('Error fetching logs:', error));
  }, [year, userId]);

  return (
    <div>
      <h1>Loggar för {year}</h1>
      {logs.map((log, index) => (
        <div key={index}>
          {/* Rendera din loggdata här */}
          <p>{log.title}</p>
          <p>{log.content}</p>
        </div>
      ))}
    </div>
  );
}

export default YearLogs;