// ImageUploader.js
import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import axios from 'axios';

const ImageUploader = ({ onUploadSuccess }) => {
  const [selectedFiles, setSelectedFiles] = useState([]); // Ändra till en array
  const [previewSources, setPreviewSources] = useState([]); // Ändra till en array

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    previewFiles(files);
    onUploadSuccess(files); // Nu är `files` en array
  };

  const previewFiles = (files) => {
    const fileReaders = Array.from(files).map(file => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        fileReader.onloadend = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = reject;
      });
    });

    Promise.all(fileReaders)
      .then(previews => {
        setPreviewSources(prevSources => [...prevSources, ...previews]);
      })
      .catch(error => {
        console.error('Error previewing files:', error);
      });
  };


  const handleRemovePreview = (event, indexToRemove) => {
    event.preventDefault();
    setPreviewSources(prevSources => prevSources.filter((source, index) => index !== indexToRemove));
    setSelectedFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove));
  };
  return (
    <div>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
        multiple
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Välj bild
        </Button>

      </label>

      {previewSources && (
      <div>
        <p>Bilder till loggen:</p>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>

        {previewSources.map((source, index) => (
          <Box padding={2} key={index} width={300}>
    <Card sx={{ padding: 1, position: 'relative' }}>
        <CardContent>
            <button 
                onClick={(event) => handleRemovePreview(event, index)} 
                style={{ 
                    position: 'absolute', 
                    top: '0', 
                    right: '0', 
                    background: 'red', 
                    color: '#fff', 
                    border: 'none', 
                    cursor: 'pointer' 
                }}
            >
                X
            </button>
            <img src={source} alt={`vald bild ${index + 1}`} style={{ width: '100%' }} />
        </CardContent>
    </Card>
</Box>
))}
</div>
      </div>
    )}
    </div>
  );
};

export default ImageUploader;