import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ImageUploader from './ImageUploader';

const UpdateServiceLog = () => {
  const { log_id } = useParams(); // Hämta loggpostens ID från URL:en
  const [userId, setUserId] = useState('');
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [boatNameModel, setBoatNameModel] = useState('');
  const [engineType, setEngineType] = useState('');
  const [servicePersonOrCompany, setServicePersonOrCompany] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [engineService, setEngineService] = useState('');
  const [sailAndRig, setSailAndRig] = useState('');
  const [electronicsAndNavigation, setElectronicsAndNavigation] = useState('');
  const [hullAndDeck, setHullAndDeck] = useState('');
  const [rudderPropellerAndDrive, setRudderPropellerAndDrive] = useState('');
  const [fuelSystem, setFuelSystem] = useState('');
  const [batteryAndElectricalSystem, setBatteryAndElectricalSystem] = useState('');
  const [sparePartsAndReplacements, setSparePartsAndReplacements] = useState('');
  const [recommendedActions, setRecommendedActions] = useState('');
  const [costAndTime, setCostAndTime] = useState('');
  const [comments, setComments] = useState('');
  const [images, setImages] = useState([]); 
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [open, setOpen] = useState(false); 
  const navigate = useNavigate();
  const [imagesToRemove, setImagesToRemove] = useState([]);

  useEffect(() => {
    if (currentUser === null) {
      navigate('/info');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Skapa en ny abort controller
    const abortController = new AbortController();

    // Hämta loggpostens data när komponenten laddas
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/servicelog/${log_id}`, { signal: abortController.signal })
      .then(response => response.json())
      .then(data => {
        // Sätt state med data från servern
        setUserId(data.userId);
        setTitle(data.title);
        setDate(moment(data.date).format('YYYY-MM-DDTHH:mm'));
        setBoatNameModel(data.boatNameModel);
        setEngineType(data.engineType);
        setServicePersonOrCompany(data.servicePersonOrCompany);
        setServiceType(data.serviceType);
        setEngineService(data.engineService);
        setSailAndRig(data.sailAndRig);
        setElectronicsAndNavigation(data.electronicsAndNavigation);
        setHullAndDeck(data.hullAndDeck);
        setRudderPropellerAndDrive(data.rudderPropellerAndDrive);
        setFuelSystem(data.fuelSystem);
        setBatteryAndElectricalSystem(data.batteryAndElectricalSystem);
        setSparePartsAndReplacements(data.sparePartsAndReplacements);
        setRecommendedActions(data.recommendedActions);
        setCostAndTime(data.costAndTime);
        setComments(data.comments);
        setImages(data.images || []); 
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error:', error);
        }
      });

    // Avbryt fetch-anropet när komponenten avmonteras
    return () => {
      abortController.abort();
    };
  }, [log_id]); // Kör useEffect när `log_id` ändras

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/servicelog/${log_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'userId': userId // Skicka med userId i headern
      }
    })
      .then(response => response.text())
      .then(data => {
        console.log(data);
        navigate('/servicelogs'); // Navigera till startsidan efter en lyckad radering
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    handleClose();
  };

  const handleRemoveExistingImage = (event, indexToRemove) => {
    event.preventDefault();
    let imageToRemove = images[indexToRemove];

    // Ta bort '/images/' från imageToRemove
    imageToRemove = imageToRemove.replace('/images/', '');

    // Lägg till bilden i imagesToRemove
    setImagesToRemove(prevImages => [...prevImages, imageToRemove]);

    // Ta bort bilden från images-staten
    setImages(prevImages => prevImages.filter((image, index) => index !== indexToRemove));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Kontrollera om användaren är densamma som har skapat loggen
    console.log('currentUser:', currentUser);
    console.log('userId:', userId);
    if (currentUser !== userId) {
      setShowLoginMessage(true);
      return;
    }

    // Återställ meddelandet
    setShowLoginMessage(false);
    // Upload the files
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const uploadResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/uploads`, {
      method: 'POST',
      body: formData
    });

    const uploadedImages = await uploadResponse.json();
    console.log('Uploaded images: ', uploadedImages);
    setUploadedImages(uploadedImages);

    // Lägg till de nya bilderna till images-arrayen
    const updatedImages = [...images, ...uploadedImages];

    // Skapa ett objekt med uppdaterade data att skicka till servern
    const serviceLogData = {
      userId,
      title,
      date,
      boatNameModel,
      engineType,
      servicePersonOrCompany,
      serviceType,
      engineService,
      sailAndRig,
      electronicsAndNavigation,
      hullAndDeck,
      rudderPropellerAndDrive,
      fuelSystem,
      batteryAndElectricalSystem,
      sparePartsAndReplacements,
      recommendedActions,
      costAndTime,
      comments,
      images: updatedImages // Använd updatedImages här
    };

    // Skicka en DELETE-begäran för varje bild i imagesToRemove
    imagesToRemove.forEach(imageToRemove => {
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/deleteimage/${imageToRemove}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'userId': userId // Skicka med userId i headern
        }
      })
        .then(response => response.text())
        .then(data => {
          console.log(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });

    //skicka till servern
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/servicelog/${log_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'userId': userId // Skicka med userId i headern
      },
      body: JSON.stringify(serviceLogData)
    })
      .then(response => response.text())
      .then(data => {
        console.log(data);
        navigate(`/showservicelog/${log_id}`); // Navigera till ShowServiceLog efter en lyckad uppdatering
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>

      <Card>
        <CardContent>
          {showLoginMessage && (
            <Alert severity="warning" onClose={() => setShowLoginMessage(false)}>
              Du har inte behörighet att uppdatera denna logg.
            </Alert>
          )}
          <Typography variant="h5">Uppdatera servicelogg</Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Rubrik"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Datum"
                    type="datetime-local"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Båtens namn/modell"
                    value={boatNameModel}
                    onChange={(event) => setBoatNameModel(event.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Motor/typ"
                    value={engineType}
                    onChange={(event) => setEngineType(event.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Serviceperson eller företag"
                    value={servicePersonOrCompany}
                    onChange={(event) => setServicePersonOrCompany(event.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Typ av service"
                    value={serviceType}
                    onChange={(event) => setServiceType(event.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Motorservice"
                    value={engineService}
                    onChange={(event) => setEngineService(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Segel och rigg"
                    value={sailAndRig}
                    onChange={(event) => setSailAndRig(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Elektronik och navigation"
                    value={electronicsAndNavigation}
                    onChange={(event) => setElectronicsAndNavigation(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Skrov och däck"
                    value={hullAndDeck}
                    onChange={(event) => setHullAndDeck(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Roder, propeller och drivning"
                    value={rudderPropellerAndDrive}
                    onChange={(event) => setRudderPropellerAndDrive(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bränslesystem"
                    value={fuelSystem}
                    onChange={(event) => setFuelSystem(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Batteri och elsystem"
                    value={batteryAndElectricalSystem}
                    onChange={(event) => setBatteryAndElectricalSystem(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Reservdelar och byten"
                    value={sparePartsAndReplacements}
                    onChange={(event) => setSparePartsAndReplacements(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Rekommenderade åtgärder"
                    value={recommendedActions}
                    onChange={(event) => setRecommendedActions(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Kostnad och tid"
                    value={costAndTime}
                    onChange={(event) => setCostAndTime(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Kommentarer"
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: { resize: 'vertical' }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <ImageUploader onUploadSuccess={setSelectedFiles} />
                    <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                      {images.map((imageUrl, index) => (
                        <Box padding={2} key={index} width={300}>
                          <Card sx={{ padding: 1, position: 'relative' }}>
                            <CardContent>
                              <button onClick={(event) => handleRemoveExistingImage(event, index)}
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  background: 'red',
                                  color: '#fff',
                                  border: 'none',
                                  cursor: 'pointer'
                                }}
                              >X</button>

                              <img src={imageUrl.replace('/images/', `${process.env.REACT_APP_SERVER_URL}/api/images/`)} alt={`Image ${index}`} style={{ width: '100%' }} />
                            </CardContent>
                          </Card>
                        </Box>
                      ))}
                      {uploadedImages.map((imageUrl, index) => (
                        <Box padding={2} key={index} width={300}>
                          <Card sx={{ padding: 1 }}>
                            <CardContent>
                              <img src={imageUrl} alt="Uppladdad bild" style={{ width: '100%' }} />
                            </CardContent>
                          </Card>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Box paddingTop={4} paddingRight={2} paddingBottom={4} paddingLeft={0}>
                <Button variant="contained" color="primary" type="submit">Uppdatera</Button>
                <Button variant="contained" color="secondary" style={{ marginLeft: '10px' }} onClick={() => navigate(`/showservicelog/${log_id}`)}>Tillbaka</Button>
              </Box>
            </Grid>
          </form>
        </CardContent>
      </Card >
      <Box paddingTop={4} paddingRight={2} paddingBottom={4} paddingLeft={0}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="warning" onClick={handleClickOpen}>Radera service</Button>
          <Dialog
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Är du säker?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Är du säker på att du vill radera denna logg? Denna åtgärd kan inte ångras.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">Avbryt</Button>
              <Button onClick={handleDelete} color="secondary">Radera</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>

    </div>
  );
}

export default UpdateServiceLog;