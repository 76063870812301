import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Home  from './Home';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useAuth } from '../../contexts/AuthContext';


export default function SearchAppBar() {
  
  
  return (
    <Box>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>

            <Link to="/info" style={{ color: 'inherit', textDecoration: 'none' }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'block', sm: 'block', mr: 6 } }}
              >
                LOGGBOKEN
              </Typography>
            </Link>
          </div>
          <Home />

        </Toolbar>
      </AppBar>
    </Box>
  );
}
