import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import LogTheme from './LogTheme';

const ServiceLogs = () => {
  const { userId } = useAuth();
  const [serviceLogs, setServiceLogs] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/servicelogs/${userId}`)
      .then(response => response.json())
      .then(data => setServiceLogs(data))
      .catch(error => console.error('Error:', error));
  }, [userId]);

  return (
    <LogTheme>
      <Box padding={5}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h1">Service</Typography>
          <Button component={Link} to="/createservicelog" variant="contained" color="primary">
            Ny service
          </Button>
        </Box>
        {serviceLogs.map(serviceLog => (
          <Card key={serviceLog._id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h4">{serviceLog.title}</Typography>
              <Typography variant="h6">{new Date(serviceLog.date).toLocaleDateString('sv-SE')}</Typography>
              <Typography variant="body1">{serviceLog.description}</Typography>
              <Button component={Link} to={`/showservicelog/${serviceLog._id}`} variant="contained" color="primary">
                Visa
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>
    </LogTheme>
  );
};

export default ServiceLogs;