import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Button, TextField, Box, Select, MenuItem } from '@mui/material';
import ImageUploader from '../Layout/ImageUploader';
import { useAuth } from '../../contexts/AuthContext';
import { useEffect } from 'react';

const Register = () => {
  const [username, setUsername] = useState('');
  const [role, setUserRole] = useState('user');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // Nytt
  const [lastName, setLastName] = useState(''); // Nytt
  const [bio, setBio] = useState(''); // Nytt
  const [profilePicture, setProfilePicture] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileInfo, setUploadedFileInfo] = useState(null); // Nytt
  const [uploadedImages, setUploadedImages] = useState([]); // Add this line

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser, userRole } = useAuth();

  useEffect(() => {
    // Lägg till en kontroll av att användaren är inloggad och har rollen admin
    if (userRole !== 'admin') {
      navigate('/'); // omdirigera till hemsidan
      // alternativt kan du visa ett felmeddelande här
    }
  }, [userRole, navigate]);


  const register = async (e) => {
    e.preventDefault();

    try {
      // Skapa en FormData-instans och lägg till den valda filen
      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      // Skicka en POST-begäran till /uploads/profile-pictures med den valda filen
      const uploadResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/uploads/profile-pictures`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Kontrollera om filuppladdningen var framgångsrik
      console.log(uploadResponse.status);
      if (uploadResponse.status === 200) {
        console.log('Filen har laddats upp framgångsrikt. Filinformation:', uploadResponse.data);


        // Skapa ett unikt slumptal
        const _id = (Math.floor(Math.random() * 1e12) + 1e12).toString().substring(1);
        console.log('_id:', _id);  // Lägg till denna rad

        // Skicka en POST-begäran till /signup med användarinformationen och filinformationen
        const imagePaths = uploadResponse.data.map(fileInfo => `/profile-pictures/${fileInfo.filename}`);
        console.log('Uploaded images: ', imagePaths);

        // Update uploadedImages state
        setUploadedImages(imagePaths);

        const signupResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/signup`, {
          _id,
          username,
          email,
          role,
          password,
          firstName,
          lastName,
          bio,
          profilePictures: imagePaths,
        });

        // Kontrollera om registreringen var framgångsrik
        console.log(signupResponse.status);
        if (signupResponse.status === 201) {
          navigate('/info'); // navigera till /home
        } else {
          setError(signupResponse.data.message);
        }
      } else {
        console.error('Fel vid filuppladdning.');
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div>
      <Container>
        <h2>Skapa ett konto</h2>
        <form onSubmit={register}>
          <Box mb={2}>
            <TextField
              type="text"
              label="Användarnamn"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="email"
              label="E-post"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label="Lösenord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          {userRole === 'admin' && (
            <Box mb={2}>
              <Select
                value={role}
                onChange={(e) => setUserRole(e.target.value)}
                required
                sx={{ minWidth: '350px' }}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'user'}>User</MenuItem>
              </Select>
            </Box>
          )}
          <Box mb={2}>
            <TextField
              type="text"
              label="Förnamn"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="text"
              label="Efternamn"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              multiline
              rows={8}
              label="Skriv lite om båten och besättningen ..."
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mb={2}>
            <ImageUploader onUploadSuccess={setSelectedFiles} />
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
              {uploadedImages.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt="Uppladdad bild" style={{ width: '30%', height: 'auto', margin: '1%' }} />
              ))}
            </div>
          </Box>
          <Box mb={2}>
            <Button type="submit"
              variant="contained"
              color="primary">
              Registrera kontot
            </Button>
          </Box>
        </form>
        {error && <p>{error}</p>}
      </Container>
    </div>
  );
}

export default Register;