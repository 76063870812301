// AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  console.log('userRole from token:', userRole);
  console.log('UserId from token:', userId);
  
  console.log('userToken från AuthContext:', userToken);
  

  useEffect(() => {
    // Försök hämta autentiseringsstatus från localStorage vid sidladdning
    const storedToken = localStorage.getItem('token');
    if (storedToken !== null) {
      setIsLoggedIn(true);
      const decoded = jwtDecode(storedToken);
      setUserId(decoded.userId);
      setUserRole(decoded.userRole);
      setCurrentUser(decoded.userId);
    } else {
      setIsLoggedIn(false);
      setUserId(null); // Återställ userId vid utloggning
      setUserRole(null); // Återställ userRole vid utloggning
    }
  }, [localStorage.getItem('token')]); 

  const login = (token) => {

      setIsLoggedIn(true);
      localStorage.setItem('token', token);
      setUserToken(token);
      console.log('Sparade token...');
      const decoded = jwtDecode(token);
      setUserId(decoded.userId);
      setUserRole(decoded.userRole);
      setCurrentUser(decoded.userId);
      setForceUpdate(prev => !prev);
  };
  
  const logout = () => {
    console.log("Logging out...");
    setIsLoggedIn(false);
    // Ta bort token från localStorage vid utloggning
    localStorage.removeItem('token');
    console.log('Tog bort token...');
    setForceUpdate(prev => !prev);
    window.location.reload();
  };

  const contextValue = {
    currentUser,
    userId,
    userRole,  
    isLoggedIn, 
    login, 
    logout, 
    setIsLoggedIn, 
    forceUpdate,
    setForceUpdate// ... andra värden och funktioner som behövs i context
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

