import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LogTheme from './LogTheme';
import { useAuth } from '../../contexts/AuthContext';

function ShowLog() {
    const { userId } = useAuth();
    const [log, setLog] = useState(null);
    const { log_id } = useParams();
    const [authorizationError, setAuthorizationError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [zoomedImageIndex, setZoomedImageIndex] = useState(null); // Add this line

    const navigate = useNavigate();
    console.log('log_id ', log_id);
    useEffect(() => {
        if (!userId) {
            setLoading(false);
            // Om userId inte är tillgängligt, vänta på nästa render för att göra fetch-anropet
            navigate('/info');
            return;
        }

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/showlog/${log_id}`)
            .then(response => response.json())
            .then(data => {
                console.log('Response data:', data);
                setLog(data);

                // Kontrollera om userId från AuthContext matchar userId från databasen
                if (data.userId !== userId) {
                    setAuthorizationError(true);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setAuthorizationError(true);
            })
            .finally(() => setLoading(false));
    }, [log_id, userId]);

    if (loading) {
        return <div>
            <h2 style={{ margin: 25 }}>Laddar sidan ...</h2></div>;
    }

    if (authorizationError) {
        return <div>
            <h2 style={{ margin: 25 }}>Du har inte behörighet att se denna logg.</h2>
        </div>;
    }
    console.log('log ', log);
    if (!log) {
        return <div>
            <h2 style={{ margin: 25 }}>Loggen kunde inte hämtas.</h2>
        </div>;
    }

    return (
        <LogTheme>
            <div>
                <Box padding={2}>
                    <Card sx={{ padding: 4 }}>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to={`/updatelog/${log._id}`}>
                                    <Button variant="contained" color="primary">Ändra</Button>
                                </Link>
                            </div>
                            <Typography variant="h2">{log.year}</Typography>
                            <Typography variant="h1">{log.title}</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Avgångstid:</Typography>
                                <Typography variant="h4">
                                    {new Date(log.departureDateTime).toLocaleString('sv-SE')}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Ankomsttid:</Typography>
                                <Typography variant="h4">
                                    {new Date(log.arrivalDateTime).toLocaleString('sv-SE')}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Vindriktning:</Typography>
                                <Typography variant="h4">{log.windDirection}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Vindstyrka (m/s):</Typography>
                                <Typography variant="h4">{log.windSpeed}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Distans (nm):</Typography>
                                <Typography variant="h4">{log.distance}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Från – Till:</Typography>
                                <Typography variant="h4">{log.position}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" marginRight={2}>Beskrivning:</Typography>
                                <Typography variant="h4">{log.description}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                        {
                            log && log.images && log.images.map((imageUrl, index) => {
                                const isZoomed = zoomedImageIndex === index;

                                return (
                                    <Box
                                        padding={2}
                                        key={index}
                                        width={300}
                                        sx={{
                                            transition: 'transform 0.2s',
                                            transform: isZoomed ? 'scale(2)' : 'none',
                                            top: isZoomed ? '50%' : '0',
                                            left: isZoomed ? '50%' : '0',
                                            margin: isZoomed ? '0 auto' : '0',
                                            zIndex: isZoomed ? 9999 : 'auto',
                                        }}
                                        onClick={() => setZoomedImageIndex(isZoomed ? null : index)}
                                    >
                                        <Card sx={{ padding: 1 }}>
                                            <CardContent>
                                                {typeof imageUrl === 'string' && (
                                                    <img
                                                        src={imageUrl.replace('/images/', `${process.env.REACT_APP_SERVER_URL}/api/images/`)}
                                                        alt={`Image ${index}`}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Box>
                                );
                            })
                        }

                    </Box>
                </Box>
            </div>
        </LogTheme>
    );
}

export default ShowLog;