import React from 'react';
import { Box, Grid, Card, CardMedia } from '@mui/material';
import LogTheme from './LogTheme';

function Info() {
    return (
        <LogTheme>
            <Box style={{ padding: '20px' }}>
                <Card elevation={8}>
                    <CardMedia
                        component="img"
                        image="/images/image6.jpg"
                        alt="Beskrivning av bilden"
                        sx={{
                            width: '100%',
                            border: '20px solid white',
                            boxSizing: 'border-box', // Se till att ramen inkluderas i bildens storlek
                        }}
                    />
                </Card>
                <h1 style={{ textAlign: 'center' }}>Välkommen!</h1>
                <div className="ingress" style={{ textAlign: 'center', margin: '0 auto', width: '80%' }}>
                    <p>Loggboken är en plats för både segel- och motorbåtsentusiaster. Här kan du skapa loggar efter varje båttur, lägga till bilder och beskriva dina upplevelser på vattnet. Numera finns också en servicebok där det går att spara sina serviceanteckningar, också det med beskrivningar och bilder.</p>
                </div>

                <Box style={{ marginTop: '20px' }}>
                    <h2>Privat</h2>
                    <p>All text och bilder som du lägger upp på Loggboken är privata. Ingen annan har åtkomst till det som du laddar upp eller skriver om du inte själv ger bort din inloggning till någon annan. Varje användare ser bara sitt eget innehåll. Testa själv genom att göra en bildsökning på Google och skriva t.ex. Loggboken eller Seglingssajten i sökfältet.</p>
                    <h2>Loggar i PDF-format</h2>
                    <p>Planen framöver är att lägga till en funktion för att kunna ladda hem sitt eget innehåll i PDF-format. Antingen som backup eller för att skriva ut på papper och bygga upp en pärm eller bok med serviceanteckningar eller båtäventyr.</p>
                    <h2>Kontakta oss</h2>
                    <p>Seglingssajtens loggbok är för närvarande i testfasen, men framåt finns planer på att göra den tillgänglig för en bredare publik. Hör av dig till <a href="mailto:info@seglingssajten.se">info@seglingssajten.se</a> om du har några idéer eller om du vill vara testpilot.</p>
                </Box>
            </Box>
        </LogTheme>
    );
}

export default Info;