import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogTheme from './LogTheme';
import Typography from '@mui/material/Typography';

export default function Home() {
  const navigate = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();
  const { currentUser, userRole } = useAuth();
  const location = useLocation();
  const message = location.state?.message;
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    // Stäng menyn när platsen ändras
    setShowMenu(false);
  }, [location]);

  // Definiera de klassnamn som behövs från LogTheme
  const linkClass = "menylink";
  const loginlogoutClass = "loginlogout";

  return (
    <LogTheme>
      <div>
        {message && <p>{message}</p>}
        <MenuIcon onClick={handleMenuClick} className="hamburger" />
        <div className={`links ${showMenu ? 'show' : ''}`}>
        <Link to="/info" className={linkClass}>Startsida</Link>
          {currentUser && <Link to="/home" className={linkClass}>Loggar</Link>}
          {currentUser && <Link to="/servicelogs" className={linkClass}>Service</Link>}
          {userRole === 'admin' && <Link to="/signup" className={linkClass}>Registrera</Link>}
          {userRole === 'admin' && <Link to="/changeuser" className={linkClass}>Ändra konto</Link>}
          {currentUser && <Link to={`/showprofile/${currentUser}`} className={linkClass}>Visa profil</Link>}
          <Typography
            className={`${linkClass}`}
            noWrap
            component="div"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (isLoggedIn) {
                logout();
                navigate('/info');
              } else {
                navigate('/login');
              }
            }}
          >
            {isLoggedIn ? "Logga ut" : "Logga in"}
          </Typography>
        </div>
      </div>
    </LogTheme>
  );
}