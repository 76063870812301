import { Container, Button, TextField, Box, Select, MenuItem, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LogTheme from '../Layout/LogTheme';
import { useAuth } from '../../contexts/AuthContext';
import ImageUploader from '../Layout/ImageUploader';
import axios from 'axios';

function ChangeProfile() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // Nytt
  const [lastName, setLastName] = useState(''); // Nytt
  const [bio, setBio] = useState(''); // Nytt
  const [profilePictures, setProfilePictures] = useState(null);
  const { userid } = useParams();
  const currentUser = useAuth();
  const navigate = useNavigate();
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);


  useEffect(() => {
    if (userid !== currentUser.userId && currentUser.role !== 'admin') {
      // Om userId inte är tillgängligt, vänta på nästa render för att göra fetch-anropet
      navigate('/info');
      return;
  }
    console.log('userid i ChangeProfile: ', userid);

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/showprofile/${userid}`)
      .then(response => response.json())
      .then(data => {
        // Sätt state med data från servern
        setUsername(data.username);
        setEmail(data.email);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setBio(data.bio);
        setProfilePictures(data.profilePictures);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [userid]); // Kör useEffect när `userId` ändras

  const handleRemoveExistingImage = (event, indexToRemove) => {
    event.preventDefault();
    let imageToRemove = profilePictures[indexToRemove];

    imageToRemove = imageToRemove.replace('/profile-pictures/', '');

    // Lägg till bilden i imagesToRemove
    setImagesToRemove(prevImages => [...prevImages, imageToRemove]);

    // Ta bort bilden från ProfilePictures-staten
    setProfilePictures(prevImages => prevImages.filter((image, index) => index !== indexToRemove));
  };

  const handleUploadSuccess = (files) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Upload the files
    try {
      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });


      // Skicka en POST-begäran till /uploads/profile-pictures med den valda filen
      const uploadResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/uploads/profile-pictures`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Kontrollera om filuppladdningen var framgångsrik
      console.log(uploadResponse.status);
      if (uploadResponse.status === 200) {
        console.log('Filen har laddats upp framgångsrikt. Filinformation:', uploadResponse.data);

        const imagePaths = uploadResponse.data.map(fileInfo => `/profile-pictures/${fileInfo.filename}`);

        // Lägg till de nya bilderna till profilePictures-arrayen
        const updatedImages = [...profilePictures, ...imagePaths];

        // Skapa ett objekt med uppdaterade data att skicka till servern
        const userData = {
          username,
          userid,
          email,
          firstName,
          lastName,
          bio,
          profilePictures: updatedImages
        };

        // Om lösenordet inte är tomt, lägg till det i userData
        if (password.trim() !== '') {
          userData.password = password;
        }

        // Skicka en DELETE-begäran för varje bild i imagesToRemove
        imagesToRemove.forEach(imageToRemove => {
          fetch(`${process.env.REACT_APP_SERVER_URL}/api/deleteimage/${imageToRemove}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ subDir: 'profile-pictures' }) // Lägg till detta
          })
            .then(response => response.text())
            .then(data => {
              console.log(data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        });
        console.log('user_id: ', userid);
        console.log('userData: ', userData);
        //skicka till servern
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/changeprofile/${userid}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        });

        const data = await response.text();
        console.log(data);
        navigate(`/showprofile/${userid}`); // Navigera till ShowProfile efter en lyckad uppdatering
      } else {
        console.error('Fel vid filuppladdning.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <LogTheme>
        <Container>
          <h2>Ändra din profil</h2>

          <form onSubmit={handleSubmit} >
            <Box mb={2}>
              <TextField
                type="text"
                label="Användarnamn"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                sx={{ minWidth: '350px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="email"
                label="E-post"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{ minWidth: '350px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="password"
                label="Lösenord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ minWidth: '350px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="text"
                label="Förnamn"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                sx={{ minWidth: '350px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="text"
                label="Efternamn"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                sx={{ minWidth: '350px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                multiline
                rows={8}
                label="Skriv lite om båten och besättningen ..."
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                sx={{ minWidth: '350px' }}
              />
            </Box>
            <Box mb={2}>
              <ImageUploader onUploadSuccess={handleUploadSuccess} />
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {uploadedImages.map((imageUrl, index) => (
                  <img key={index} src={imageUrl} alt="Uppladdad bild" style={{ width: '30%', height: 'auto', margin: '1%' }} />
                ))}
              </div>
            </Box>

            <Box display="flex" flexWrap="wrap" mx={-2}>
              {profilePictures && Array.isArray(profilePictures) && profilePictures.map((path, index) => (
                <Box key={index} width={300} mx={2}>
                  <Card sx={{ padding: 1, position: 'relative' }}>
                    <CardContent sx={{ padding: 2 }}>
                      <button onClick={(event) => handleRemoveExistingImage(event, index)}
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          background: 'red',
                          color: '#fff',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >X</button>

                      {path && typeof path === 'string' && (
                        <img src={path.replace('/profile-pictures/', `${process.env.REACT_APP_SERVER_URL}/api/profile-pictures/`)} alt="User" style={{ width: '100%' }} />
                      )}
                    </CardContent>
                  </Card>
                </Box>
              ))}

            </Box>

            <Box mt={2}>
              <Button type="submit" variant="contained">Skicka in</Button>
            </Box>

          </form>
        </Container>
      </LogTheme>
    </div>
  );
}

export default ChangeProfile;