import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button, TextField, MenuItem } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import ImageUploader from './ImageUploader';
import Alert from '@mui/material/Alert';


const CreateLog = () => {
  const { userId } = useAuth();
  const [title, setTitle] = useState('');
  const [year, setYear] = useState('');
  const [departureDateTime, setDepartureDateTime] = useState('');
  const [arrivalDateTime, setArrivalDateTime] = useState('');
  const [windDirection, setWindDirection] = useState('');
  const [windSpeed, setWindSpeed] = useState('');
  const [distance, setDistance] = useState('');
  const [position, setPosition] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { currentUser } = useAuth();
  const [showLoginMessage, setShowLoginMessage] = useState(false); // Statiskt tillstånd för att visa eller dölja meddelandet
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === null) {
      navigate('/info');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Kontrollera om användaren är inloggad innan du fortsätter med att skapa loggen
    if (!currentUser) {
      // Visa meddelandet om användaren inte är inloggad
      setShowLoginMessage(true);
      return;
    }

    // Återställ meddelandet om användaren är inloggad och göra resten av din kod för att hantera loggskapandet
    setShowLoginMessage(false);
    // ...

    // Upload the files
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const uploadResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/uploads`, {
      method: 'POST',
      body: formData
    });

    const uploadedImages = await uploadResponse.json();
    console.log('Uploaded images: ', uploadedImages);
    // Skapa ett unikt slumptal
    const _id = (Math.floor(Math.random() * 1e12) + 1e12).toString().substring(1);
    console.log('_id:', _id);  // Lägg till denna rad

    // Skapa ett objekt med data att skicka till servern
    const logData = {
      _id,
      userId,
      title,
      year,
      departureDateTime,
      arrivalDateTime,
      windDirection,
      windSpeed,
      distance,
      position,
      description,
      uploadedImages
    };
    if (logData.distance === '') {
      logData.distance = null;
    }
    // skicka till servern
    try {
      const createLogResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/createlog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(logData)
      });

      if (!createLogResponse.ok) {
        throw new Error(`HTTP error! status: ${createLogResponse.status}`);
      }

      const responseData = await createLogResponse.json();
      navigate(`/showlog/${responseData._id}`);

    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (

    <Card>
      <CardContent>
        {showLoginMessage && (
          <Alert severity="warning" onClose={() => setShowLoginMessage(false)}>
            Du måste vara inloggad för att skapa en ny logg.
          </Alert>
        )}
        <Typography variant="h5">Ny logg</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Rubrik"
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="År"
                type="number"
                value={year}
                onChange={e => setYear(e.target.value)}
                fullWidth
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Avgångstid"
                type="datetime-local"
                value={departureDateTime}
                onChange={e => setDepartureDateTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Ankomsttid"
                type="datetime-local"
                value={arrivalDateTime}
                onChange={e => setArrivalDateTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Vindriktning"
                value={windDirection}
                onChange={e => setWindDirection(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Vindstyrka (m/s)"
                value={windSpeed}
                onChange={e => setWindSpeed(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Distans (nm)"
                type="number"
                value={distance}
                onChange={e => setDistance(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Från - Till"
                value={position}
                onChange={e => setPosition(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Beskrivning"
                value={description}
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ImageUploader onUploadSuccess={setSelectedFiles} />
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {uploadedImages.map((imageUrl, index) => (
                      <img key={index} src={imageUrl} alt="Uppladdad bild" style={{ width: '30%', height: 'auto', margin: '1%' }} />
                    ))}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit"
                variant="contained"
                color="primary">
                Skapa logg
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card >

  );
};

export default CreateLog;